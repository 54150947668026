import { createAsyncThunk } from "@reduxjs/toolkit";
import { login} from "../../services/AuthService";


export const loginUserAsync = createAsyncThunk(
  "user/Login",
  async (data: any, thunkAPI) => {
    const response = await login(data);
    return response;
  }
);

