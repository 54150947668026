import { default as http } from "../rest/http";


export const fetchLinkToken = async () => {
  try {
    const response = await http.get("/transaction-service/plaid/link_token");
    return response;
  } catch (error) {
    console.error("Error fetching link token:", error);
    throw error;
  }
};

export const fetchPublicAccessToken = async (data: any) => {
  try {
    const response = await http.post("/transaction-service/plaid/access_token",data);
    return response;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};

export const fetchAccountDetail = async () => {
  try {
    const response = await http.get("/transaction-service/plaid/accounts");
    return response;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};


