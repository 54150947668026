const MeetCoachIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M16.5 21V19C16.5 17.9391 16.0786 16.9217 15.3284 16.1716C14.5783 15.4214 13.5609 15 12.5 15H6.5C5.43913 15 4.42172 15.4214 3.67157 16.1716C2.92143 16.9217 2.5 17.9391 2.5 19V21" stroke="#525252" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.5 11C11.7091 11 13.5 9.20914 13.5 7C13.5 4.79086 11.7091 3 9.5 3C7.29086 3 5.5 4.79086 5.5 7C5.5 9.20914 7.29086 11 9.5 11Z" stroke="#525252" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.5 21V19C22.4993 18.1137 22.2044 17.2528 21.6614 16.5523C21.1184 15.8519 20.3581 15.3516 19.5 15.13" stroke="#525252" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.5 3.13C17.3604 3.35031 18.123 3.85071 18.6676 4.55232C19.2122 5.25392 19.5078 6.11683 19.5078 7.005C19.5078 7.89318 19.2122 8.75608 18.6676 9.45769C18.123 10.1593 17.3604 10.6597 16.5 10.88" stroke="#525252" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}
export default MeetCoachIcon;