import { createSlice } from "@reduxjs/toolkit";
import { loginUserAsync } from "../thunks/AuthThunk";

// Interface for LoginResponse
interface LoginResponse {
  access_token: string;
  refresh_token: string;
}

// Defining possible status values
export const STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  IDLE: "idle",
  FAILED: "failed",
};

// Defining the initial state structure
interface AuthInitialState {
  loginResponse: LoginResponse | null;
  loginStatus: string;
  error: string | null;
}

// Setting up the initial state
const initialState: AuthInitialState = {
  loginResponse: null, // No login response initially
  loginStatus: STATUS.IDLE, // Default status is idle
  error: null, // No error at the beginning
};

// Creating the AuthSlice
const AuthSlice = createSlice({
  name: "auth",
  initialState, // Using the defined initial state
  reducers: {
    resetLoginStatus: (state) => {
      state.loginStatus = STATUS.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserAsync.pending, (state) => {
        state.loginStatus = STATUS.LOADING;
        state.error = null;
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.loginResponse = action.payload;
        state.loginStatus = STATUS.SUCCESS;
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.loginStatus = STATUS.FAILED;
        state.error = action.error.message ?? null;
      });
  },
});

// Exporting the actions and reducer
export const { resetLoginStatus } = AuthSlice.actions;
export default AuthSlice.reducer;
