// Drawer.tsx
import React from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface DrawerProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    content: React.ReactNode; // Content can be any valid React node
}

const Drawer: React.FC<DrawerProps> = ({ isOpen, onClose, title, content }) => {
    return (
        <Transition appear show={isOpen} as={React.Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <div className="fixed inset-0 bg-neutral-100 bg-opacity-30" /> {/* Optional backdrop */}
                <div className="fixed inset-0 flex justify-center">
                    <div className="pointer-events-none fixed inset-x-0 bottom-0 flex max-w-full">
                        <Transition.Child
                            as={React.Fragment}
                            enter="transition-transform duration-300 ease-in-out"
                            enterFrom="translate-y-full"  // Start from the bottom of the screen
                            enterTo="translate-y-0"        // Slide to its normal position
                            leave="transition-transform duration-300 ease-in-out"
                            leaveFrom="translate-y-0"      // Start from its normal position
                            leaveTo="translate-y-full"      // Slide back down out of view
                        >
                            <DialogPanel className="pointer-events-auto w-full max-w-md transform bg-white shadow-xl rounded-t-lg">
                                <div className="flex flex-col h-full">
                                    <div className="px-4 py-6 sm:px-6">
                                        <div className="flex items-center justify-between">
                                            <DialogTitle className="textlarge700 text-neutral-900 font-serif">{title}</DialogTitle>
                                            <button
                                                type="button"
                                                onClick={onClose}
                                                className="relative rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                            >
                                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                            </button>
                                        </div>
                                    </div>
                                    {/* Content area that scrolls, fixed height with overflow */}
                                    <div className="relative flex-1 overflow-y-auto max-h-[85vh]"> {/* Set height to 90% of viewport */}
                                        <div className="p-4 textlarge400 font-serif">{content}</div>
                                    </div>
                                </div>
                            </DialogPanel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default Drawer;
