import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { ChatAsync, FetchChatMessagesAsync, FetchConversationsAsync } from "../../../store/thunks/ChatThunk";
import SendIcon from "../../../assets/SendIcon";
import AppLogo from "../../../assets/AppLogo";
import BackIcon from "../../../assets/BackIcon";
import HamBurgerMenu from "../../../assets/HamBurgerMenu";
import './style.css';

interface TempItem {
    type: "user" | "system";
    msg: string;
}

const Chat = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();
    const { userId, name } = location.state || {};
    const [tempArray, setTempArray] = useState<TempItem[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [conversationID, setConversationID] = useState<string>('');
    const [menuOpen, setMenuOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const questionRefs = useRef<(HTMLDivElement | null)[]>([]);
    const headerRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [selectedConversationId, setSelectedConversationId] = useState<string | null>('');
    const conversations = useSelector((state: RootState) => state.ChatStore.chatConversations);

    useEffect(() => {
        if (selectedConversationId) {
            setTempArray([]);
            dispatch(FetchChatMessagesAsync(selectedConversationId)).then(response => {
                if (response.payload?.transactions) {
                    const messages = response.payload.transactions
                        .map((transaction: any) => ({
                            type: transaction.sender_type === "user" ? "user" : "system",
                            msg: transaction.message_text
                        }))
                        .reverse();

                    setTempArray(messages);
                }
            });
        }
    }, [selectedConversationId, dispatch]);

    const mainContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleResize = () => {
            if (mainContainerRef.current) {
                mainContainerRef.current.style.height = `${window.innerHeight}px`;
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(FetchConversationsAsync());
    }, [dispatch, conversationID]);

    useEffect(() => {
        const headerHeight = headerRef.current?.offsetHeight || 0;
        const latestUserMessageIndex = tempArray.map(item => item.type).lastIndexOf("user");
        const latestUserMessageRef = questionRefs.current[latestUserMessageIndex];

        if (latestUserMessageRef && containerRef.current) {
            const questionPosition = latestUserMessageRef.offsetTop;
            containerRef.current.scrollTo({
                top: questionPosition - headerHeight -20,
                behavior: 'smooth'
            });
        }
    }, [tempArray]);

    const send = async (query: string) => {
        if (inputValue.trim() === '') return;

        setTempArray(prevArray => [...prevArray, { type: 'user', msg: query }, { type: 'system', msg: "" }]);
        setInputValue('');
        setIsLoading(true);
        const formData = { message: query, conversation_id: conversationID };

        try {
            const response = await dispatch(ChatAsync(formData));
            if (response.payload) {
                if (conversationID === '') {
                    setConversationID(response.payload.conversation_id);
                }

                setTimeout(() => {
                    setTempArray(prevArray => [
                        ...prevArray.slice(0, -1),
                        { type: 'system', msg: response.payload.message_text }
                    ]);
                    setIsLoading(false)
                }, 1000);
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error:', error);
        }
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && !event.shiftKey && !isLoading) {
            event.preventDefault();
            send(inputValue);
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClick = (id: string) => {
        setSelectedConversationId(id);
        setConversationID(id)
        toggleMenu()
    };

    const onClickLogo = () => {
        navigate(`/landingPage?userId=${userId}&fullName=${name}`);
    };

    return (
        <div ref={mainContainerRef} className="chat-container">
            <div ref={headerRef} className="header  flex justify-between pt-16 items-center px-3 pb-3 bg-customBgColor fixed left-0 w-full z-10">
                <div className='left-0' onClick={() => navigate(-1)}>
                    <BackIcon />
                </div>
                <div className="flex justify-center items-center mx-auto">
                    <AppLogo />
                    <div className="ml-2 carouselTitle">
                        <h4>Finley</h4>
                    </div>
                </div>
                <div onClick={toggleMenu}>
                    <HamBurgerMenu />
                </div>
                <div className={`mobile-menu w-[70%] fixed top-0 right-0 h-full  bg-white shadow-lg z-20 transform transition-transform duration-300 ${menuOpen ? 'translate-x-0' : 'translate-x-[110%]'}`}>
                    <button onClick={toggleMenu} className="text-right pt-4 pl-4 text-xl">✕</button>
                    <div className="pt-4">
                        <div className="ml-7 mb-4 chatDay text-chatDayColor">Today</div>
                        <ul className="px-5">
                            {conversations?.transactions.map((transaction) => (
                                <li
                                    style={{
                                        maxWidth: '200px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                    key={transaction.id}
                                    className={`px-4 py-2 cursor-pointer text3 text-wrap ${selectedConversationId === transaction.id ? 'bg-blackButton text-white rounded-full' : ''}`}
                                    onClick={() => handleClick(transaction.id)}
                                >
                                    {transaction.title}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="lg:flex lg:justify-center pt-20 ">
                <div className="sm:relative flex flex-col justify-between lg:justify-end h-screen lg:w-[800px]">
                    <div ref={containerRef} className="overflow-hidden pb-[1000px] overflow-y-auto scrollbar-hide scroll-container lg">
                        <div className="pb-14 lg:px-5">
                            {tempArray.map((item, index) => (
                                <div className={` px-3 flex  py-2 my-10 rounded-2xl w-fit max-w-[75%] ${item.type === 'user' ? 'bg-gray-300 px-5 ml-auto   mr-3' : ''}`} key={index} ref={(el) => (questionRefs.current[index] = el)}>
                                    {item.type === 'system' ? <div className="mt-1.5"><AppLogo /></div> : ""}
                                    {item.msg === "" ? (
                                        <div className="ml-3 loader">
                                            <div className="loader-dot"></div>
                                            <div className="loader-dot"></div>
                                            <div className="loader-dot"></div>
                                        </div>
                                    ) : (
                                        <div className={`text3 text-wrap mt-[2px] ${item.type === 'user' ? '' : 'ml-4'}`}>{item.msg}</div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="fixed bg-background w-full flex bottom-0 left-0 right-0 justify-center pb-10 pt-3  px-3 lg:px-0">
                <div className="relative w-full">
                    <textarea
                        value={inputValue}
                        onKeyDown={handleKeyDown}
                        onChange={(event) => setInputValue(event.target.value)}
                        onInput={(e) => {
                            const textarea = e.target as HTMLTextAreaElement;
                            textarea.style.height = '48px';
                            textarea.style.height = `${textarea.scrollHeight}px`;
                        }}
                        placeholder="Ask Finley about your money..."
                        className="px-4 pr-10 py-3 w-full resize-none rounded-md overflow-hidden"
                        style={{ height: '48px' }}
                    />
                        <div   onClick={() => !isLoading && send(inputValue)}   className="absolute bottom-2 right-3 transform -translate-y-1/2 cursor-pointer">
                        <SendIcon disabled={isLoading} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;
