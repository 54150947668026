import { createAsyncThunk } from "@reduxjs/toolkit";
import { submitSurvey } from "../../services/SurveyService";


export const submitSurveyAsync = createAsyncThunk(
  "user/survey",
  async (data: any, thunkAPI) => {
    const response = await submitSurvey(data);
    return response;
  }
);

