import React, { FC } from 'react';
import InputMask from 'react-input-mask';

interface PhoneInputProps {
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PhoneInput: FC<PhoneInputProps> = ({ value, onChange }) => {
  return (
    <InputMask className="font-serif w-full text4 rounded-md   p-2 pl-3 pr-14 border border-custom-slate "
      mask="+1(999)999-9999" 
  
      alwaysShowMask
      maskChar="_" 
      value={value} 
      onChange={onChange} 
    />
  );
};

export default PhoneInput;
