import { createAsyncThunk } from "@reduxjs/toolkit";
import { VerifyEmail, VerifyOtp,AccountAcknowledge,SaveAccount,ResendOtp } from "../../services/OnBoardingService"

export const VerifyEmailAsync = createAsyncThunk(
    "onboarding/verifyEmail",
    async (data: any, thunkAPI) => {
      try {
        const response = await VerifyEmail(data);
        return response; 
      } catch (error) {
     
        return thunkAPI.rejectWithValue(error);
      }
    }
  );

  export const VerifyOtpAsync = createAsyncThunk(
    "onboarding/verifyOtp",
    async (data: any, thunkAPI) => {
      try {
        const response = await VerifyOtp(data);
        return response; 
      } catch (error) {
        let errorMessage = "An unknown error occurred";
  
        if (error instanceof Error) {
          errorMessage = error.message;
        }
  
        return thunkAPI.rejectWithValue(errorMessage);
      }
    }
  );

  export const AccountAcknowledgeAsync = createAsyncThunk(
    "onboarding/accountAcknowledge",
    async (_, thunkAPI) => {
      try {
        const response = await AccountAcknowledge();
        return response; 
      } catch (error) {
        let errorMessage = "An unknown error occurred";
  
        if (error instanceof Error) {
          errorMessage = error.message;
        }
  
        return thunkAPI.rejectWithValue(errorMessage);
      }
    }
  );

  export const ResendOtpAsync = createAsyncThunk(
    "onboarding/resendotp",
    async (data: any, thunkAPI) => {
      try {
        const response = await ResendOtp(data);
        return response; 
      } catch (error) {
        let errorMessage = "An unknown error occurred";
  
        if (error instanceof Error) {
          errorMessage = error.message;
        }
  
        return thunkAPI.rejectWithValue(errorMessage);
      }
    }
  );

  export const SaveAccountAsync = createAsyncThunk(
    "onboarding/saveaccount",
    async (data: any, thunkAPI) => {
      try {
        const response = await SaveAccount(data);
        return response; 
      } catch (error) {
        let errorMessage = "An unknown error occurred";
  
        if (error instanceof Error) {
          errorMessage = error.message;
        }
  
        return thunkAPI.rejectWithValue(errorMessage);
      }
    }
  );

  export const saveAccountAndAcknowledge = createAsyncThunk(
    'onboarding/saveAccountAndAcknowledge',
    async (payload: { phoneNumber: string, password: string }, { dispatch, rejectWithValue }) => {
      try {
        // Dispatch SaveAccountAsync with email and password
        const saveAccountResult = await dispatch(SaveAccountAsync(payload)).unwrap();
  
        // Dispatch AccountAcknowledgeAsync after successful SaveAccountAsync
        const accountAcknowledgeResult = await dispatch(AccountAcknowledgeAsync()).unwrap();
  
        // Return both results
        return { saveAccountResult, accountAcknowledgeResult };
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );