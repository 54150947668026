import { useEffect, useState } from "react";
import logo from "../../../assets/Logo.png";
import googleLogo from "../../../assets/googleIcon.svg";
import LockIcon from "../../../assets/LockIcon";
import EmailIcon from "../../../assets/EmailIcon";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { loginUserAsync } from "../../../store/thunks/AuthThunk";
import { STATUS } from "../../../store/slices/AuthSlice";

interface LoginState {
    email: string;
    password: string;
}

const Login = () => {
    const navigate = useNavigate();
    const [loginState, setLoginState] = useState<LoginState>({
        email: '',
        password: '',
    });
    const dispatch = useDispatch<AppDispatch>();
    const loginDetails = useSelector((state: RootState) => state.AuthStore);

    const handleLogin = async () => {
         await dispatch(loginUserAsync({ email: loginState.email, password: loginState.password }));
    };

    useEffect(() => {
        const fetchData = async () => {
            if (loginDetails.loginStatus === STATUS.SUCCESS) {
                const accessToken: string | undefined = loginDetails?.loginResponse?.access_token;
                localStorage.setItem('accessToken', accessToken as string);
                // Redirect or perform any other logic here
                navigate('/Home')
            } else if (loginDetails.loginStatus === STATUS.FAILED) {
                setLoginState(prevState => ({
                    ...prevState,
                    error: "Login failed. Please check your credentials.",
                    email: '',
                    password: ''
                }));
            }
        };

        fetchData();
    }, [loginDetails]);
   

    return (
        <div className="flex flex-col px-12 justify-center items-center h-screen ">
     
            <div className="mt-24 relative">
                <input
                    type="text"
                    onChange={(e) => setLoginState(prevState => ({ ...prevState, email: e.target.value }))}
                    value={loginState.email}
                    className="w-[343px] border-[1.5px] px-3 h-[56px] pl-14 border-black rounded-[20px]"
                    placeholder="abc@example.com"
                />
                <EmailIcon />
            </div>
            <div className="mt-4">
                <div className="relative">
                    <input
                        type="password"
                        onChange={(e) => setLoginState(prevState => ({ ...prevState, password: e.target.value }))}
                        value={loginState.password}
                        className="w-[343px] border-[1.5px] pl-14 px-3 h-[54px] border-black rounded-2xl"
                        placeholder="*********"
                    />
                    <LockIcon />
                </div>
            </div>
            {loginDetails.loginStatus === STATUS.FAILED && <div className="text-red-500 mt-2">Login failed. Please check your credentials</div>}
            <div className="text cursor-pointer text-blue mt-4 underline">
                Forgot Password?
            </div>
            <button
                onClick={handleLogin}
                className={`cursor-pointer bg-loginBg mt-4 text2 text-white px-8 py-2 rounded-3xl ${loginDetails.loginStatus === STATUS.LOADING ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={loginDetails.loginStatus === STATUS.LOADING} // Disable button when loading
            >
                {loginDetails.loginStatus === STATUS.LOADING? 'Logging in...' : 'Login'}
            </button>
            <div className="mt-16 border-t-2 pt-[15px] w-full flex justify-center ">
                <div className="flex ">
                    <div className="text3">Don’t have an account? </div>
                    <div className="text2 cursor-pointer text-blue ml-1 underline">Register</div>
                </div>
            </div>
            <div className="cursor-pointer text4 flex items-center mt-[15px] border-[1.5px] border-black rounded-2xl px-[30px] py-[10px]">
                <div className="mr-2"><img src={googleLogo} alt="googleLogo" /></div>
                <div>Continue with Google</div>
            </div>
        </div>
    );
}

export default Login;
