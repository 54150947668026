
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Login from '../pages/Home/Login';
import Home from '../pages/Home/Home';
import NotFound from '../../components/common/NotFound';

import OnBoarding from '../pages/OnBoardingStep/OnBoarding';
import LandingState from '../pages/LandingState/LandingState';
import Survey from '../pages/Survey/Survey';
import SurveyPage from '../pages/Survey/SurveyQuePage';
import Chat from '../pages/chat/Chat';
import SurveyQuePage from '../pages/Survey/SurveyQuePage';




export function Main() {
    const isAuthenticated = !!localStorage.getItem("accessToken"); // Check if token exists
    const location = useLocation();

    return (
        // <div className="lg:container lg:max-w-md h-screen flex flex-col bg-customBgColor p-3">

        <Routes>
            {isAuthenticated ? (
                <>
                    <Route path="/home" element={<Home />} />
                    <Route path="/onboarding" element={<OnBoarding />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/survey" element={<Survey />} />
                    <Route path="/survey-que" element={<SurveyPage/>} />
                    {/* <Route path="/survey-submit" element={<SurveySubmit />} /> */}
                    <Route path="/chat" element={<Chat />} />
                    <Route path="/survey-que" element={<SurveyQuePage/>} />
                    
                    <Route path="/" element={<LandingState />} />
             
                    {/* Handle 404 for authenticated users */}
                    <Route path="*" element={<NotFound />} />
                </>
            ) : (
                <>
                
                    <Route path="/" element={<Chat />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/onboarding" element={<OnBoarding />} />
                    {/* If user is not authenticated, redirect to login */}
                    {location.pathname !== '/' && (
                        <Route path="*" element={<Navigate to="/" />} />
                    )}
                </>
            )}
        </Routes>
        // </div>
    );
}

export default Main;
