import { useState, useRef, useEffect } from "react";
import AppLogo from "../../../assets/AppLogo";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { submitSurveyAsync } from "../../../store/thunks/SurveyThunk";
import SurveySubmit from "./SurveySubmitPage";
const SurveyPage = () => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [responses, setResponses] = useState<string[]>([]);
    const [isReadyForNext, setIsReadyForNext] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const mainContainerRef = useRef<HTMLDivElement>(null);
    const questionRefs = useRef<(HTMLDivElement | null)[]>([]);
    const headerRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch<AppDispatch>();

    const questions: { question: string; options: string[]|null }[] = [
        {
            question: "What’s your current level of financial stress?",
            options: [
                "No stress",
                "Low stress",
                "Moderate stress",
                "Overwhelmingly stressed",
            ],
        },
        {
            question: "Do you have money left over at the end of each month?",
            options: [
                "Yes, I do",
                "Not as much as I'd like",
                "I'm at a negative balance often",
            ],
        },
        {
            question: "Do you have enough saved for retirement?",
            options: [
                "I do have enough saved",
                "Probably not",
                "I’m not really sure",
            ],
        },
        {
            question: "Could you handle a major emergency expense today? (Around $1000)",
            options: [
                "Yes I could",
                "I don’t think so",
            ],
        },
        {
            question: "Do you pay off your full credit card balance every month?",
            options: [
                "I pay it off completely",
                "I carry a balance",
                "I don’t have a credit card",
            ],
        },
        {
            question: "Do you have student debt?",
            options: [
                "I do",
                "No, I don’t",
            ],
        },
        {
            question: "", // Empty question for SurveySubmit component
            options: null
        }
    ];
    const [answeredQuestions, setAnsweredQuestions] = useState<boolean[]>(Array(questions.length).fill(false));

    useEffect(() => {
        if (currentQuestion < questions.length) {
            setIsReadyForNext(false);
            setShowOptions(false);
            const timeout = setTimeout(() => {
                setIsReadyForNext(true);
                setTimeout(() => setShowOptions(true), 500);
            }, 500);
            return () => clearTimeout(timeout);
        }
    }, [currentQuestion]);
    
    useEffect(() => {
        if (answeredQuestions.every(Boolean)) {
            dispatch(submitSurveyAsync(generatePayload()))
        }
    }, [dispatch, answeredQuestions]);
    

    const handleOptionClick = (option: string, question: number) => {
        if (!answeredQuestions[question]) {
            const updatedResponses = [...responses];
            updatedResponses[question] = option;
            setResponses(updatedResponses);
            setAnsweredQuestions((prev) => {
                const updated = [...prev];
                updated[question] = true;
                return updated;
            });
            setTimeout(() => {
                handleNextQuestion();
            }, 800);
        } else {
            const updatedResponses = [...responses];
            updatedResponses[question] = option;
            setResponses(updatedResponses);
            setAnsweredQuestions((prev) => {
                const updated = [...prev];
                updated[question] = true;
                return updated;
            });
        }
    };
    const handleNextQuestion = () => {
        setCurrentQuestion((prev) => Math.min(prev + 1, questions.length - 1));
        setTimeout(() => {
            handleScrollToCurrentQuestion();
        }, 0);
    };
    const handleScrollToCurrentQuestion = () => {
        const headerHeight = headerRef.current?.offsetHeight || 0;
        const nextQuestionRef = questionRefs.current[currentQuestion + 1];
        if (nextQuestionRef && mainContainerRef.current) {
            const questionTop = nextQuestionRef.offsetTop;
            mainContainerRef.current.scrollTo({
                top: questionTop - headerHeight + 110,
                behavior: "smooth",
            });
        }
    };
    const generatePayload = () => {
        return {
            questions: questions
                .map((question, index) => ({
                    question: question.question,
                    answer: responses[index] || "",
                }))
                .filter(item => item.answer !== ""), // Exclude questions with empty answers
        };
    };
    return (
        <div className="relative h-screen">
            <div
                ref={headerRef}
                className="flex justify-between pt-16 items-center px-3 pb-3 backdrop-blur-sm absolute left-0 w-full z-10"
            >
                <div className="flex justify-center items-center mx-auto">
                    <AppLogo />
                    <div className="ml-2 carouselTitle">
                        <h4>Finley</h4>
                    </div>
                </div>
            </div>
            <div
                className="h-full pt-32 pb-[1000px] overflow-y-auto scroll-smooth"
                ref={mainContainerRef}
            >
                <div className="relative w-full">
                    {questions.map((item, index) => (
                        <div
                            key={index}
                            ref={(el) => (questionRefs.current[index] = el)}
                            className={`w-full transition-all duration-500 ease-in-out mb-3 ${index === currentQuestion && isReadyForNext
                                ? 'fade-slide-in'
                                : 'fade-slide-out'
                                }`}
                            style={{
                                opacity: index === currentQuestion ? 1 : index < currentQuestion ? 1 : 0,
                            }}
                        >
                            <div className="px-3">
                                {item.question ? (
                                    <>
                                        <div className="text3 text-darkBlue">
                                            {item.question}
                                        </div>
                                        <div className="w-full mt-7">
                                            {item.options && item.options.map((option, idx) => (
                                                <button
                                                    key={idx}
                                                    onClick={() => handleOptionClick(option, index)}
                                                    className="bg-blackButton carouselSubTitle text-white w-full py-2 px-6 rounded-full mb-4"
                                                    style={{
                                                        opacity: answeredQuestions[index]
                                                            ? (responses[index] === option ? 1 : 0.5)
                                                            : 1,
                                                        transition: 'opacity 0.3s ease, background-color 0.3s ease',
                                                    }}
                                                >
                                                    {option}
                                                </button>
                                            ))}
                                        </div>
                                    </>
                                ) : (
                                    <SurveySubmit onSubmit={() => dispatch(submitSurveyAsync(generatePayload()))}/>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
export default SurveyPage;