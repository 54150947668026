import { default as http } from "../rest/http";

export const submitSurvey = async (data: any) => {
  try {
    const response = await http.post("/master-data/onboarding/submit-survey", data);
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};
