import Header from "../../common/Header";
import Footer from '../../common/Footer';

const Home = () => {
    return (
        <div >
            <div className="px-6"><Header /></div>
            <div className="px-6   flex flex-col justify-between   h-[92vh] pt-[62px]">
                <Footer />
            </div>
        </div>)
}


export default Home;
