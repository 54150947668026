
import { useNavigate } from 'react-router';
import HomeIcon from '../../assets/HomeIcon';
import FinleyAIIcon from '../../assets/FinleyAIIcon';
import SpendingIcon from '../../assets/SpendingIcon';
import MeetCoachIcon from '../../assets/MeetCoachIcon';
function Footer() {
    const navigate = useNavigate();
    return (
        <div className='fixed bg-white flex justify-between bottom-0 left-0 right-0 px-5 h-[58px] pt-3 pb-[10px] shadow-lg'>
            <div className='flex justify-between w-full max-w-[800px] mx-auto'>
                <div className='flex flex-col items-center'>
                    <HomeIcon />
                    <div className='text-xs mt-1 text-green-600'>Home</div>
                </div>
                <div className='flex flex-col items-center'>
                    <SpendingIcon />
                    <div className='text-xs mt-1'>Spending</div>
                </div>
                <div className='flex flex-col items-center'>
                    <MeetCoachIcon />
                    <div className='text-xs mt-1'>Meet Coach</div>
                </div>
                <div className='flex flex-col items-center' onClick={() => navigate('/chat')}>
                    <FinleyAIIcon />
                    <div className='text-xs mt-1'>Finley AI</div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
