
// import BackIcon from '../../assets/BackIcon';
import AppLogo from '../../assets/AppLogo';

export function Header() {
    return (
        <div className="flex justify-between items-center  pt-16  pb-3">
            {/* <div className=' left-0' onClick={() => navigate(-1)}>
                <BackIcon />
            </div> */}


            <div className='flex justify-center items-center mx-auto'>
                <AppLogo />
                <div className='ml-2 carouselTitle'><h4>Finley</h4></div>
            </div>
        </div>

    )
}
export default Header;