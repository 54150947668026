import React, { forwardRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData, setStepValid, validateStep } from "../../../store/slices/OnBoardingSlice";
import { RootState } from "../../../store/store";
import PhoneInput from "../../common/PhoneInput";
import MessageComponent from '../../common/MessageComponent';
import { MESSAGES } from '../../../types/CommonType';
import PasswordInput from '../../common/PasswordInput'; // Import your PasswordInput

interface StepProps {
    onNext: () => void;
}

const Step3 = forwardRef<HTMLDivElement, StepProps>(({ onNext }, ref) => {
    const dispatch = useDispatch();
    const phone = useSelector((state: RootState) => state.OnboardingStore.phone);
    const name = useSelector((state: RootState) => state.OnboardingStore.userOnboarding.first);
    const password = useSelector((state: RootState) => state.OnboardingStore.password);
    const isValid: boolean | undefined = useSelector((state: RootState) => state.OnboardingStore.isValid.step3);
    const [messageType, setMessageType] = useState<'Info' | 'Error' | 'Success'>('Info');

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const phoneNumber = e.target.value;
        dispatch(updateFormData({ phone: phoneNumber }));

        // Validate the phone number length (10 digits)
        const strippedPhone = phoneNumber.replace(/\D/g, ''); // Remove non-digit characters
        if (strippedPhone.length === 11) {
            dispatch(setStepValid(true)); // Mark step as valid when phone is filled correctly
        } else {
            dispatch(setStepValid(false)); 
        }
    };

    const isInvalid = () => {
        // Check if the password is invalid
        const isPasswordInvalid =
            !password || // Check if password is empty
            password.length < 12 || // Check minimum length
            !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{12,}$/.test(password);

        // Check if the phone is invalid
        const isPhoneInvalid =
            !phone || // Check if phone is empty
            phone.replace(/\D/g, '').length !== 11; // considering 11 because if ISD code

        return isPasswordInvalid || isPhoneInvalid;
    };

    const handlePasswordChange = (password: string) => {
        dispatch(updateFormData({ password })); // Dispatch the password change to the store
        if (password.length < 12) {
            setMessageType('Error'); // Less than 12 characters
        } else if (password && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{12,}$/.test(password)) {
            dispatch(setStepValid(true)); // Mark step as valid when password is filled
            setMessageType('Success');
        } else {
            setMessageType('Error');
            dispatch(setStepValid(false)); 
        }
    };

    const handleNext = () => {
        if (!isInvalid()) { // Proceed only if there are no validation errors
            dispatch(validateStep({ step: 'step3', isValid: true }));
            setTimeout(() => {
                onNext();
            }, 800);
        }
    };

    return (
        <div ref={ref}
        className={`transition-opacity duration-300 pt-2 ${!isValid ? 'opacity-100 pointer-events-auto' : 'opacity-50 pointer-events-none'}`} >
        
            <div className="text3">
                One last step, {name}. Enter your phone number and create a password to finalize your account info.
            </div>
            <div>
                <div className="text3 pt-3">Phone</div>
                <PhoneInput value={phone} onChange={handlePhoneChange} />
            </div>
            <div className="text3 pt-3">Create Password</div>
            <div>
                <PasswordInput
                    password={password}
                    setPassword={handlePasswordChange} // Pass the handler to update password
                />
            </div>
            <div className='pt-3' >
                <MessageComponent type={messageType} message={MESSAGES.PASSWORD_FORMAT_MESSAGE} />
            </div>
            <div className="w-full bg-customBgColor pt-3 mb-6" >
                <button
                    onClick={handleNext}
                    className={`flex justify-center items-center w-full text3 text-white py-2 rounded-full ${isInvalid() ? 'bg-disabledButton/50 cursor-not-allowed' : 'bg-loginBg cursor-pointer'}`}
                    disabled={isInvalid()}
                >
                    Done
                </button>
            </div>
            <div ref={ref}></div>
        </div>
    );
});

export default Step3;

