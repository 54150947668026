const SendIcon = ({ disabled = false }: { disabled?: boolean }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
            style={{
                opacity: disabled ? 0.5 : 1, // Dims the icon if disabled
                pointerEvents: disabled ? 'none' : 'auto', // Prevents interaction if disabled
            }}
        >
            <path d="M22 2L11 13" stroke={disabled ? "#B0B0B0" : "black"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22 2L15 22L11 13L2 9L22 2Z" stroke={disabled ? "#B0B0B0" : "black"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
export default SendIcon;
