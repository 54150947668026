import { createSlice } from "@reduxjs/toolkit";
import { submitSurveyAsync } from "../thunks/SurveyThunk";


// Defining possible status values
export const STATUS = {
    LOADING: "loading",
    SUCCESS: "success",
    IDLE: "idle",
    FAILED: "failed",
};

// Defining the initial state structure
interface SubmitSurveyInitialState {
    submitSurveyResponse: null;
    submitSurveyStatus: string;
    error: string | null;
}

// Setting up the initial state
const initialState: SubmitSurveyInitialState = {
    submitSurveyResponse: null, // No login response initially
    submitSurveyStatus: STATUS.IDLE, // Default status is idle
    error: null, // No error at the beginning
};

const SubmitSurveySlice = createSlice({
    name: "survey",
    initialState,
    reducers: {
        restSurveyStatus: (state) => {
            state.submitSurveyStatus = STATUS.IDLE;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(submitSurveyAsync.pending, (state) => {
                state.submitSurveyStatus = STATUS.LOADING;
                state.error = null;
            })
            .addCase(submitSurveyAsync.fulfilled, (state, action) => {
                state.submitSurveyResponse = action.payload;
                state.submitSurveyStatus = STATUS.SUCCESS;
            })
            .addCase(submitSurveyAsync.rejected, (state, action) => {
                state.submitSurveyStatus = STATUS.FAILED;
                state.error = action.error.message ?? null;
            });
    },
});

// Exporting the actions and reducer
export const { restSurveyStatus } = SubmitSurveySlice.actions;
export default SubmitSurveySlice.reducer;
