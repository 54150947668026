
import { useNavigate } from "react-router";


type SurveySubmitProps = {
    onSubmit: () => void;
};
const SurveySubmit = ({ onSubmit }: SurveySubmitProps) => {
    const navigate = useNavigate();

    const handleSubmit = () => {
        onSubmit(); 
        navigate('/home'); 
    };

    return (
        <div className="px-3">
            <div className="mt-[10px] carouselTitle">
                Great! Thanks for filling me in.
            </div>
            <div className="text3 mt-2 text-darkBlue">
                I’ll reach out from time to time to see if things are feeling different.
            </div>
            <div className="text3 text-darkBlue mt-4">
                We’re all set - let’s show you how Finley works.
            </div>
            <div className="w-full mt-7 ">
                <button  className="bg-blackButton carouselSubTitle text-white w-full py-2 px-6 rounded-full mb-4" onClick={handleSubmit}>
                    Let's Go
                </button>
            </div>
        </div>
    )
}
export default SurveySubmit;
