import React, { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import { Input } from '@headlessui/react';

interface PasswordInputProps {
    password: string;
    setPassword: (password: string) => void;
    placeholder?: string;
    name?: string;
}

const PasswordInput: React.FC<PasswordInputProps> = ({ password, setPassword, placeholder = "Enter your password", name = "password" }) => {
    const [type, setType] = useState<string>('password');
    const [icon, setIcon] = useState<JSX.Element>(<EyeOff size={20} />);

    const handleToggle = () => {
        if (type === 'password') {
            setType('text');
            setIcon(<Eye size={20} />);
        } else {
            setType('password');
            setIcon(<EyeOff size={20} />);
        }
    };

    return (
        <div className="relative pt-1">
            <Input
                style={{ color: '#0F172A' }}
                type={type}
                name={name}
                placeholder={placeholder}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="text4 text-slate-900 bg-white rounded-md w-full border border-slate-300 h-[36px] px-3 focus:border-slate-300 focus:outline-none"
            />
            <span className="absolute right-3 top-2.5 cursor-pointer" onClick={handleToggle}>
                {icon}
            </span>
        </div>
    );
};

export default PasswordInput;
