import { useNavigate } from "react-router";
import Header from "../../common/Header";

const Survey = () => {
    const navigate = useNavigate();

    return (
        <div className="px-3">
            <Header />
            <div className="mt-[10px] carouselTitle  tracking-extra-tight">
                Help me understand where you stand today with your money.
            </div>
            <div className="text3 mt-3 text-darkBlue">
                Here are a few questions to help me understand how you feel about money today.
            </div>
            <div className="text3 text-darkBlue mt-4">
                It’ll only take about about 1 minute.
            </div>
            <div className="w-full mt-7 ">
                <button onClick={()=>{navigate("/survey-que")}} className="bg-blackButton carouselSubTitle text-white w-full py-2 px-6 rounded-full mb-4">
                    Let's Go
                </button>
            </div>
        </div>
    )
}
export default Survey;
