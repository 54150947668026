import http from "../rest/http";

export const AccountAcknowledge = async () => {
    try {
      const response = await http.post("/master-data/onboarding/acknowledge",{});
      return response;
    } catch (error) {
      console.error("Error while acknowledging account:", error);
      throw error;
    }
  };
  export const VerifyEmail = async (data: any) => {
    try {
      const response = await http.postWithoutHeader("/master-data/onboarding/verify-email", data);
      return response;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  };

  
  export const VerifyOtp = async (data:any) => {
    try {
      const response = await http.postWithoutHeader("/master-data/onboarding/verify-otp",data);
      return response;
    } catch (error) {
      console.error("Invalid otp :", error);
      throw error;
    }
  };
  
  export const ResendOtp = async (data:any) => {
    try {
      const response = await http.postWithoutHeader("/master-data/onboarding/resend-otp",data);
      return response;
    } catch (error) {
      console.error("Error sending otp:", error);
      throw error;
    }
  };

  export const SaveAccount = async (data:any) => {
    try {
      const response = await http.post("/master-data/onboarding/account",data);
      return response;
    } catch (error) {
      console.error("Error saving account:", error);
      throw error;
    }
  };
  