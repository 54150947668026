import { createAsyncThunk } from "@reduxjs/toolkit";
import { ChatService, FetchChatMessagesService, FetchConversations } from "../../services/ChatService";


export const ChatAsync = createAsyncThunk(
  "user/chat",
  async (data: any, thunkAPI) => {
    const response = await ChatService(data);
    return response;
  }
);

export const FetchConversationsAsync = createAsyncThunk(
  "user/conversations",
  async ( thunkAPI) => {
    const response = await FetchConversations();
    return response;
  }
);

export const FetchChatMessagesAsync = createAsyncThunk(
  "user/messages",
  async (conversationId:any, thunkAPI) => {
    const response = await FetchChatMessagesService(conversationId);
    return response;
  }
);
