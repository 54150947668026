import { default as http } from "../rest/http";

export const login = async (data: any) => {
  try {
    const response = await http.post("/master-data/authentication/login", data);
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};
