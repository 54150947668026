import { default as http } from "../rest/http";

export const ChatService = async (data: any) => {
  try {
    const response = await http.post("/transaction-service/conversations", data);
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const FetchConversations = async () => {
  try {
    const response = await http.get("/transaction-service/conversations");
    console.log("response",response)
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};


export const FetchChatMessagesService = async ( conversationID:any) => {
  try {
    const response = await http.get(`/transaction-service/conversations/${conversationID}/messages`);
    console.log("response",response)
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};


// https://api.boom.myfinley.com/transaction-service/conversations